// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "y_dV";
export var mvpAppDevCaseSection = "y_d3";
export var mvpAppDevChoiceSection = "y_d0";
export var mvpAppDevClientQuotes = "y_d4";
export var mvpAppDevCostSection = "y_dY";
export var mvpAppDevExpertiseSection = "y_d2";
export var mvpAppDevIndustriesSection = "y_dS";
export var mvpAppDevPracticesSection = "y_dZ";
export var mvpAppDevPrimeSection = "y_dQ";
export var mvpAppDevProcessSection = "y_dW";
export var mvpAppDevProjLogoSection = "y_d1";
export var mvpAppDevServicesSection = "y_dR";
export var mvpAppDevTechSection = "y_dX";
export var mvpAppDevTypesSection = "y_dT";